<template>
    <div id="app" class="container">
        <h1 class="my-4">Orders</h1>
        
        <div class="row mb-3">
            <div class="col-md-4 mb-3">
                <div class="input-group">
                    <label for="orderCount" class="input-group-text">Number of orders to create:</label>
                    <input type="number" id="orderCount" class="form-control" v-model="orderCount" min="1">
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="input-group">
                    <label for="orderStartDate" class="input-group-text">Order Start Date:</label>
                    <input type="date" id="orderStartDate" class="form-control" v-model="orderStartDate">
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="input-group">
                    <label for="orderEndDate" class="input-group-text">Order End Date:</label>
                    <input type="date" id="orderEndDate" class="form-control" v-model="orderEndDate">
                </div>
            </div>
            <div class="col-md-12">
                <button class="btn btn-primary me-2" @click="generate_order">Generate Orders</button>
            </div>
        </div>
        <p class="text-muted">{{ new_order }}</p>
        
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <div v-else>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead class="table-dark">
                        <tr>
                            <th>
                                <a href="#" @click.prevent="setOrderField('order_id')">Order ID</a>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="Search Order ID" v-model="searchOrderId" @keyup.enter="fetchOrderById">
                                    <button class="btn btn-secondary" @click="fetchOrderById">Search</button>
                                </div>

                            </th>
                            <th>
                                <a href="#" @click.prevent="setOrderField('order_date')">Order Date</a>
                            </th>
                            <th>User ID
                             <div>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="Search Order by User ID" v-model="searchOrderUserId" @keyup.enter="fetchOrderByUserId">
                                    <button class="btn btn-secondary" @click="fetchOrderByUserId">Search</button>
                                </div>
                             </div> 
                            </th>
                            <th>Username</th>
                            <th>Order Status</th>
                            <th>
                                <a href="#" @click.prevent="setOrderField('total_price')">Total Price</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in paginatedData" :key="item.order_id">
                            <td>{{ item.order_id }}</td>
                            <td>{{ item.order_date }}</td>
                            <td>{{ item.user_id }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.status }}</td>
                            <td>{{ item.total_price }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- Pagination Controls -->
            <div class="d-flex justify-content-between align-items-center my-3" v-if="!searchOrderId">
                <button type="button" class="btn btn-secondary" @click="prevPage" :disabled="currentPage === 1">Prev</button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <button type="button" class="btn btn-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
            </div>
            
            <!-- Page Size Selector -->
            <div class="form-group" v-if="!searchOrderId">
                <label for="pageSize">Items per page:</label>
                <select id="pageSize" class="form-select" v-model="pageSize" @change="changePageSize">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
            </div>
        </div>

        <!-- New div for updating order status -->
        <div class="row mt-5">
            <div class="col-md-6 mb-3">
                <div class="input-group">
                    <label for="orderId" class="input-group-text">Order ID:</label>
                    <input type="number" id="orderId" class="form-control" v-model="orderId" min="1">
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="input-group">
                    <label for="orderStatus" class="input-group-text">Order Status:</label>
                    <select id="orderStatus" class="form-select" v-model="orderStatus">
                        <option value="Pending">Pending</option>
                        <option value="Cancelled">Processing</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Cancelled">Cancelled</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12">
                <button class="btn btn-primary me-2" @click="update_order_status">Update Order Status</button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'App',
    data() {
        return {
            orderCount : 5,
            orderField: 'order_date', // Default order field
            apiResult: [],
            loading: true,
            currentPage: 1,
            pageSize: 10,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.apiResult.length / this.pageSize);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = parseInt(start) + parseInt(this.pageSize);
            console.log("start")
            console.log(start)
            console.log("end")
            console.log(end)
            return  this.apiResult.slice(start, end)
        },
    },
    created() {
        this.fetchOrders();
    },
    methods: {
        async fetchOrders() {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/orders?order_by=${this.orderField}`);
                const data = await response.json();
                this.apiResult = data; // Keep all fetched data here
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                this.loading = false;
            }
        },
        // General function to set the order field and fetch orders
        setOrderField(field) {
            this.loading = true;
            this.orderField = field;
            if (this.searchOrderUserId) {
                this.fetchOrderByUserId()
            } else {
                this.fetchOrders(); // Fetch orders 
            }
            
        },        
        async fetchOrderById() {
            if (this.searchOrderId) {
                this.loading = true;
                try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/orders/${this.searchOrderId}?order_by=${this.orderField}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.apiResult = data;
                console.log(data);
                setTimeout(() => {
                    this.new_order = '';
                }, 5000);
                //set this.new_order to false after 5 seconds 
                

                // Update your component's state or perform actions based on the response
            } catch (error) {
                console.error("Error creating order: ", error);
            } finally {
                
                this.loading = false;
            }
            }
        }, 
        async fetchOrderByUserId() {
            if (this.searchOrderUserId) {
                this.loading = true;
                try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/orders/user/${this.searchOrderUserId}?order_by=${this.orderField}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.apiResult = data;
                console.log(data);
                setTimeout(() => {
                    this.new_order = '';
                }, 5000);
                //set this.new_order to false after 5 seconds 
                

                // Update your component's state or perform actions based on the response
            } catch (error) {
                console.error("Error creating order: ", error);
            } finally {
                
                this.loading = false;
            }
            }
        },
        async generate_order() {
            this.loading = true;
            const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
            this.new_order = ''
            const orderDetails = {
                // Example order details - replace with your actual data structure
                quantity: this.orderCount,
                start_date: this.orderStartDate || today,
                end_date: this.orderEndDate || today
            };
            try {
                const response = await fetch(process.env.VUE_APP_API_URL + '/orders', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderDetails),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.new_order = data.message
                console.log('Order created:', data);
                await this.fetchOrders()
                setTimeout(() => {
                    this.new_order = '';
                }, 5000);
                //set this.new_order to false after 5 seconds 
                

                // Update your component's state or perform actions based on the response
            } catch (error) {
                console.error("Error creating order: ", error);
            } finally {
                
                this.loading = false;
            }
        },
        async update_order_status() {
            this.loading =true
            const newOrderStatus = {
                // Example order details - replace with your actual data structure
                status: this.orderStatus,
            };
            try {
                const response = await fetch(process.env.VUE_APP_API_URL + '/orders/' + this.orderId + '/status', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newOrderStatus),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.new_order = data.message
                console.log('Order updated:', data);
                await this.fetchOrders()
                setTimeout(() => {
                    this.new_order = '';
                }, 5000);
                //set this.new_order to false after 5 seconds 
                

                // Update your component's state or perform actions based on the response
            } catch (error) {
                console.error("Error creating order: ", error);
            } finally {
                
                this.loading = false;
            }
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        },
        changePageSize() {
            this.currentPage = 1; // Reset to first page to avoid being out of range
        },
    }
};

</script>